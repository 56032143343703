import {css as styled} from "@emotion/react"

const styling = (theme) => styled`

    @font-face {
        font-family: 'Comfortaa';
        src: url('/fonts/Comfortaa-Medium.ttf') format('ttf');
    }

    .container {
        position: relative;
        min-width: 100vw;
        min-height: 100vh;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: #1F212D;
        background-size: cover;

        ${theme.breakpoints.down('lg')} {
            min-height: 70vh;
            background-color: #EB5558;
        }
    }

    .container-image {
        position: relative;
        padding: 0;
        max-width: 100vw;
        width: 100%;
        z-index: 0;
        object-fit: cover;
    }

    .container-info {
        display: flex;
        flex-direction: column;
        margin-left: 0;
        padding: ${theme.spacing(15)};
        width: 750px;

        ${theme.breakpoints.up(1800)} {
            width: 40%;
        }

        ${theme.breakpoints.down('lg')} {
            width: 600px;
            padding: ${theme.spacing(8)};
        }

        ${theme.breakpoints.down('md')} {
            width: 100%;
            padding: ${theme.spacing(8)};
        }

        ${theme.breakpoints.down('sm')} {
            padding: ${theme.spacing(8, 4)};
        }
    }

    .form-box {
        display: flex;
        flex-direction: column;
    }

    .form-input {
        color: #000;
        background-color: #fff;
        border-radius: 4px;
        width: 100%;
        margin: ${theme.spacing(1, 0)};
        outline: none;
    }

    .form-btn {
        background-color: #EB5558;
        color: white;
        border-radius: 50px;
        font-family: 'Comfortaa', sans-serif !important;
        font-size: 0.9rem;
        font-weight: 800 !important;
        width: 200px;
        height: 50px;
        text-transform: capitalize;
        margin-top: ${theme.spacing(5)};
        padding-bottom: 4px;
        transition: 0.3s ease;

        ${theme.breakpoints.up(1800)} {
            font-size: 1.1rem;
            width: 240px;
            height: 70px;
        }

        ${theme.breakpoints.down('md')} {
            margin: auto;
            margin-top: ${theme.spacing(6)};
            background-color: #1F212D;
            width: 100%;
        }
    }

    .form-btn:hover {
        background-color: #222532;
    }

    h1 {
        font-size: 4rem;
        color: #fff;

        ${theme.breakpoints.down(560)} {
            font-size: 3rem;
        }

        ${theme.breakpoints.down(440)} {
            font-size: 2rem;
        }
    }

    p {
        color: #fff;
        font-family: 'Comfortaa', sans-serif !important;
        font-weight: 400 !important;
        line-height: 2rem;

        ${theme.breakpoints.down('md')} {
            color: #000;
        }

        ${theme.breakpoints.up('xl')} {
            font-size: 1.2rem;
        }
    }

`

export default styling