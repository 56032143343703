import React from 'react';
import styles from './styles';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Container, TextField, Typography, useMediaQuery } from '@mui/material';
import image from '../../../static/Images/general/Shoreditch.jpg';

const SpeakToUs = ({ title, paragraph, button }) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<div css={styles(theme)}>
			<Container sx={{ backgroundColor: mobile ? '#EB5558' : '', backgroundImage: !mobile ? `url(${image})` : '' }} id="contact" className="container">
				{/* {!mobile ? <img className="container-image" src={image} alt="image" /> : ''} */}
				<Container className="container-info">
					<Typography pb={3} className="container-title" component={'h1'}>
						{title}
					</Typography>
					<Typography pb={3} className="container-paragraph" component={'p'}>
						{paragraph}
					</Typography>
					<form
						className="container-form"
						name="contact"
						method="POST"
						data-netlify="true"
						netlify-honeypot="bot-field"
					>
						<Box className="form-box">
							<TextField
								className="form-input"
								required
								variant="outlined"
								fullWidth
								id="name"
								name="Name"
								placeholder="First name"
							/>
							<TextField
								className="form-input"
								required
								variant="outlined"
								fullWidth
								id="email"
								name="Email"
								placeholder="Email"
							/>
							<TextField
								multiline
								required
								variant="outlined"
								minRows={3}
								className="form-input input-message"
								id="message"
								name="Message"
								placeholder="Type message"
							/>
							<input type="hidden" name="form-name" value="contact" />
							<Button className="form-btn" type="submit">
								{button}
							</Button>
						</Box>
					</form>
				</Container>
			</Container>
		</div>
	);
};

export default SpeakToUs;
